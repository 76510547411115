import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import DOMPurify from 'dompurify';

import { FetchService } from '../../service/FetchService';
import configData from "../../config.json";
import Navbar from "../Navbar";
import Navbar_1 from "../Navbar_1";
import Footer from "../Footer";
import Footer_1 from "../Footer_1";
import $ from 'jquery';
// import '~video-react/dist/video-react.css'; // import css
import { Player } from 'video-react';

function ProjectDetails() {
  const { state } = useLocation();
  const params = useParams();
  const id = params.productName;//state && state.data; //"65ebf1d80c95a59e6167c3b9";//params.id;
  const [data, setData] = useState(null);
  const [products, setProducts] = useState([]);
  const [show, setShow] = useState(false);
  const [videos, setVideos] = useState('')
  const location = useLocation()

  // Function to create a slug from the title
  const createSlug = (title) => {
    return title.toLowerCase().replace(/[^a-z0-9-]/g, '-').replace(/-+/g, '-').replace(/^-|-$/g, '');
  };

  useEffect(() => {
    $('body').removeClass('overflow-hidden');
    $('.footer-info').removeClass('z-inc');
    var prevScrollPos = window.pageYOffset;
    window.onscroll = function () {
      var currentScrollPos = window.pageYOffset;
      // Scroll down 
      if (prevScrollPos < currentScrollPos) {
        $(".header").addClass("header-hidden").removeClass("header-visible");
        $("#tile-1").addClass("header-hidden-m").removeClass("header-visible-m");
        $('.footer-info').removeClass('z-inc');
      }
      // Scroll up 
      else {
        var scroll = $(window).scrollTop();
        if (scroll <= 100) {
          $(".header").removeClass("header-hidden").removeClass("header-visible");
          $("#tile-1").removeClass("header-hidden-m").removeClass("header-visible-m");
          $('.footer-info').removeClass('z-inc');
        } else {
          $(".header").removeClass("header-hidden").addClass("header-visible");
          $("#tile-1").removeClass("header-hidden-m").addClass("header-visible-m");
          $('.footer-info').removeClass('z-inc');
        }
      }
      prevScrollPos = currentScrollPos;
    };

    var width = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;
    if (width <= 768) {

      $("main").click(function () {
        $('.overlay-123').removeClass('d-block');
      });

      $(window).scroll(function () {
        $(".footer-info").removeClass("z-inc");
        if ($(window).scrollTop() + $(window).height() > ($(document).height() - 280)) {
          //you are at bottom
          $(".footer-info").addClass("z-inc");
        }
      });

      $(".footer-info .animate-image span").click(function () {
        $('.footer-info').addClass('z-inc');
        $('#btn-back-to-top').addClass('d-none');
        $('.overlay-123').addClass('d-block');
        disableScroll();
      });

    }

    var width = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;
    if (width >= 768) {

      $(window).scroll(function () {
        $(".footer-info").removeClass("z-inc");
        if ($(window).scrollTop() + $(window).height() > ($(document).height() - 660)) {
          //you are at bottom
          $(".footer-info").addClass("z-inc");
          console.log(width);
        }
      });

      $(".animate-image").hover(
        function () {
          $(".footer-info").addClass("z-inc");
          $('#btn-back-to-top').addClass('d-none');
          //$('body').addClass('overflow-hidden');
          $('.overlay-123').addClass('d-block');
          disableScroll();
        },
        function () {
          $('#btn-back-to-top').removeClass('d-none');
          //$('body').removeClass('overflow-hidden');
          $('.overlay-123').removeClass('d-block');
          enableScroll();
        }
      );
    }

    $(window).scroll(function () {
      var scroll = $(window).scrollTop();
      if (scroll >= 200) {
        $(".header").removeClass("bg-none");
        $("#btn-back-to-top").addClass("d-block");
      } else {
        $(".header").addClass("bg-none");
        $("#btn-back-to-top").removeClass("d-block");
      }
    });


    $(".overlay-123").click(function () {
      $('.overlay-123').removeClass('d-block');
      setTimeout(function () { $('.footer-info').removeClass('z-inc'); }, 500);
      enableScroll();
    });

    // left: 37, up: 38, right: 39, down: 40,
    // spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
    var keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

    function preventDefault(e) {
      e.preventDefault();
    }

    function preventDefaultForScrollKeys(e) {
      if (keys[e.keyCode]) {
        preventDefault(e);
        return false;
      }
    }

    // modern Chrome requires { passive: false } when adding event
    var supportsPassive = false;
    try {
      window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
        get: function () { supportsPassive = true; }
      }));
    } catch (e) { }

    var wheelOpt = supportsPassive ? { passive: false } : false;
    var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

    // call this to Disable
    function disableScroll() {
      window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
      window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
      window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
      window.addEventListener('keydown', preventDefaultForScrollKeys, false);
    }

    // call this to Enable
    function enableScroll() {
      window.removeEventListener('DOMMouseScroll', preventDefault, false);
      window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
      window.removeEventListener('touchmove', preventDefault, wheelOpt);
      window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
    }
  })

  useEffect(() => {
    const fetchService = new FetchService();
    const config = {
      method: 'get',
      url: configData.SERVER_URL + 'product/projectslist?items=200',
      headers: {
        'Content-Type': 'application/json',
        'X-Auth-Token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MDc0NjEwMTQsImlkIjoiNjViNjBiMjIwYmEzMDQ3NTg4YjA5M2RkIiwiaWF0IjoxNzA3Mzc0NjE0fQ.xR-t4a6cl38I27i-PWoinjzDzjd5ncdkBCwaj2rBl1Y'
      }
    }
    fetchService.getService(config).then(data => setProducts(data["result"]));


  }, [id, location]);

  useEffect(() => {
    const fetchService = new FetchService();
    setShow(false);
    // Find the item based on the slug
    const item = products && products.find(item => createSlug(item.productName) === id);
    if (item) {
      const config = {
        method: 'get',
        url: configData.SERVER_URL + 'product/read/' + item._id,
        headers: {
          'Content-Type': 'application/json',
          'X-Auth-Token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MDc0NjEwMTQsImlkIjoiNjViNjBiMjIwYmEzMDQ3NTg4YjA5M2RkIiwiaWF0IjoxNzA3Mzc0NjE0fQ.xR-t4a6cl38I27i-PWoinjzDzjd5ncdkBCwaj2rBl1Y'
        }
      }
      fetchService.getService(config).then(data => setData(data["result"]));
    }
  }, [id, products]);

  useEffect(()=> {
    setVideos(data?.project_video?.split(','))
  }, [data, location])

  

  const showAllCategory = (item) => {
    const category = item.industry_category + ',' + item.scope_category;
    let splitArray = category.split(',');

    // Add spaces after each comma
    let formattedString = splitArray.join(', ');

    // Replace the last comma with "and" and remove the space after it
    formattedString = formattedString.replace(/,([^,]*)$/, ' and$1');
    return formattedString;


  }

  const showAllCategoryWithoutC = (item) => {
    const category = item.scope_category;
    let splitArray = category.split(',');

    // Add spaces after each comma
    let formattedString = splitArray.join(', ');

    // Replace the last comma with "and" and remove the space after it
    formattedString = formattedString.replace(/([^,]+)(,|\b)/g, '<span>$1$2</span>');
    return formattedString;


  }

  const showMoreCategory = (item) => {
    const category = item.industry_category + ',' + item.scope_category;
    let data = category.split(',');
    return (
      <>
        {data && data.map((item, index) => (
          <li>
            <div key={item} className="aDiv color-black fw-bold ibm-serif">
              {index === data.length - 1 ? "and " + item : item}
            </div>
          </li>
        ))}
      </>
    );

  }

  // Function to create a slug from the title
  const relativeSlug = (title) => {
    if (!title) return false;
    return title.toLowerCase().replace(/[^a-z0-9-]/g, '-').replace(/-+/g, '-').replace(/^-|-$/g, '');
  };

  const relativeData = () => {
    // Function to find the index of the product with a specific _id
    //products.find(item => createSlug(item.productName) === id
    const findIndexById = (_id) => products.findIndex((product) => createSlug(product.productName) === _id);
    // Get the index of the current product
    const currentIndex = products && findIndexById(id);
    //Function to get the previous and next product based on the current index
    const getPreviousAndNextProduct = (currentIndex, totalProducts) => {
      const previousIndex = currentIndex - 1 >= 0 ? currentIndex - 1 : totalProducts - 1;
      const nextIndex = currentIndex + 1 < totalProducts ? currentIndex + 1 : 0;
      return {
        previous: products[previousIndex],// !== null ? products[previousIndex] : null,
        next: products[nextIndex] // !== null ? products[nextIndex] : null,
      };
    };

    const isVideo = (url) => {
      const videoExtensions = ['mp4', 'webm', 'ogg', 'avi', 'mov', 'wmv', 'flv', 'mkv'];
      const extension = url.split('.').pop().toLowerCase();
      return videoExtensions.includes(extension);
    };

    // // Get previous and next product based on the current index
    const { previous, next } = currentIndex !== null && getPreviousAndNextProduct(currentIndex, products.length);
    return (
      <>
        <div className="single-proj">
          <Link className="link-fs-22"
            to={`/project/${relativeSlug(previous && previous.productName)}`}
          // to={{ pathname: previous && previous.productName.replace(/\s+/g, '-').toLowerCase(), state: { data: previous && previous._id }}}
          >
            {previous && 
              isVideo(previous?.images) ? (
                <video loop autoPlay muted  className="img-fluid d-block w-100">
                  <source src={configData.API_BASE_URL + "/" + previous?.images} type={`video/${previous?.images.split('.').pop()}`} />
                </video>
              ) : (
                <img src={configData.API_BASE_URL + "/" + previous?.images} alt="Media content"  className="img-fluid d-block w-100" />
              )
            // <img src={configData.API_BASE_URL + '/' + previous.images} className="d-block w-100" alt="..." />
            }
            <div className="container">
              <h2 className="fw-bold pt-3 fs-22 mb-0 color-black">{previous && previous.productName}</h2>
              <p className="lh-scope color-black">{previous && showAllCategory(previous)}</p>
              <div className="left-icon flip-1 position-absolute">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={38}
                  height={38}
                  viewBox="0 0 38 38"
                  fill="none"
                >
                  <path
                    d="M19.2273 12.4654L18.218 13.4748L22.9992 18.2561L12.1086 18.2561L12.1086 19.7436L22.9992 19.7436L18.218 24.5248L19.2273 25.5342L25.7617 18.9998L19.2273 12.4654ZM19.2181 0.512305C21.7743 0.512305 24.1779 0.997351 26.4289 1.96745C28.6799 2.93758 30.638 4.25417 32.3032 5.91719C33.9683 7.58025 35.2865 9.53587 36.2578 11.784C37.2292 14.0322 37.7148 16.4344 37.7148 18.9906C37.7148 21.5467 37.2298 23.9503 36.2597 26.2013C35.2896 28.4524 33.973 30.4105 32.31 32.0756C30.6469 33.7407 28.6913 35.059 26.4431 36.0303C24.1949 37.0016 21.7928 37.4873 19.2366 37.4873C16.6804 37.4873 14.2768 37.0023 12.0258 36.0322C9.77478 35.062 7.81669 33.7454 6.15158 32.0824C4.48643 30.4194 3.16819 28.4637 2.19685 26.2156C1.22551 23.9674 0.739847 21.5652 0.739847 19.009C0.739847 16.4529 1.22489 14.0493 2.19499 11.7983C3.16513 9.54723 4.48171 7.58914 6.14473 5.92399C7.80779 4.25887 9.76341 2.94064 12.0116 1.96931C14.2598 0.997972 16.6619 0.512304 19.2181 0.512305Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
          </Link>
        </div>


        <div className="single-proj pt-3 pt-md-0">
          <Link className="link-fs-22"
            to={`/project/${relativeSlug(next && next.productName)}`}
          // to={{ pathname: next && next.productName.replace(/\s+/g, '-').toLowerCase(), state: { data: next && next._id }}}
          >
            {next && 
              isVideo(next?.images) ? (
                <video loop autoPlay muted  className="img-fluid d-block w-100">
                  <source src={configData.API_BASE_URL + "/" + next?.images} type={`video/${next?.images.split('.').pop()}`} />
                </video>
              ) : (
                <img src={configData.API_BASE_URL + "/" + next?.images} alt="Media content"  className="img-fluid d-block w-100" />
              )
              // <img src={configData.API_BASE_URL + '/' + next.images} className="d-block w-100" alt="..." />
              }
            <div className="container">
              <h2 className="fw-bold pt-3 fs-22 mb-0 color-black">{next && next.productName}</h2>
              <p className="lh-scope color-black">{next && showAllCategory(next)}</p>
              <div className="right-icon position-absolute">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={38}
                  height={38}
                  viewBox="0 0 38 38"
                  fill="none"
                >
                  <path
                    d="M19.2273 12.4654L18.218 13.4748L22.9992 18.2561L12.1086 18.2561L12.1086 19.7436L22.9992 19.7436L18.218 24.5248L19.2273 25.5342L25.7617 18.9998L19.2273 12.4654ZM19.2181 0.512305C21.7743 0.512305 24.1779 0.997351 26.4289 1.96745C28.6799 2.93758 30.638 4.25417 32.3032 5.91719C33.9683 7.58025 35.2865 9.53587 36.2578 11.784C37.2292 14.0322 37.7148 16.4344 37.7148 18.9906C37.7148 21.5467 37.2298 23.9503 36.2597 26.2013C35.2896 28.4524 33.973 30.4105 32.31 32.0756C30.6469 33.7407 28.6913 35.059 26.4431 36.0303C24.1949 37.0016 21.7928 37.4873 19.2366 37.4873C16.6804 37.4873 14.2768 37.0023 12.0258 36.0322C9.77478 35.062 7.81669 33.7454 6.15158 32.0824C4.48643 30.4194 3.16819 28.4637 2.19685 26.2156C1.22551 23.9674 0.739847 21.5652 0.739847 19.009C0.739847 16.4529 1.22489 14.0493 2.19499 11.7983C3.16513 9.54723 4.48171 7.58914 6.14473 5.92399C7.80779 4.25887 9.76341 2.94064 12.0116 1.96931C14.2598 0.997972 16.6619 0.512304 19.2181 0.512305Z"
                    fill="white"
                  />
                </svg>
              </div>

            </div>
          </Link>
        </div>

      </>
    );

  }

  const addmoreProjectData = (data) => {
    const showMore = () => setShow(!show);
    return (
      <>
        <div className="my-5">
          <div className={show ? "m-auto read-more-btn bg-white px-0 py-0 border rounded-pill expanded" : "m-auto read-more-btn bg-white px-0 py-0 border rounded-pill"} id="project-description-wrap">
            {!show &&
              <button
                className="read-more-btn bg-white text-center px-5 py-3 border w-auto rounded-pill"
                onClick={showMore}
                id="more_btn"
              >
                READ MORE
                <svg
                  width={31}
                  height={17}
                  viewBox="0 0 31 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.48278 16.3536L6.87297 14.7438L11.9071 9.65625H0.851562V7.34383H11.9071L6.87297 2.25629L8.48278 0.646484L16.3363 8.50004L8.48278 16.3536ZM16.9797 15.6451V13.3326H30.1432V15.6451H16.9797ZM16.9797 3.6675V1.35504H30.1432V3.6675H16.9797ZM21.6047 9.65625V7.34383H30.1432V9.65625H21.6047Z"
                    fill="#1C1B1F"
                  />
                </svg>
              </button>
            }
            {show &&
              <button
                className="read-more-btn bg-white text-center px-5 py-3 border w-auto rounded-pill dis-none"
                onClick={showMore}
                id="more_btn"
              >
                READ MORE
                <svg
                  width={31}
                  height={17}
                  viewBox="0 0 31 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.48278 16.3536L6.87297 14.7438L11.9071 9.65625H0.851562V7.34383H11.9071L6.87297 2.25629L8.48278 0.646484L16.3363 8.50004L8.48278 16.3536ZM16.9797 15.6451V13.3326H30.1432V15.6451H16.9797ZM16.9797 3.6675V1.35504H30.1432V3.6675H16.9797ZM21.6047 9.65625V7.34383H30.1432V9.65625H21.6047Z"
                    fill="#1C1B1F"
                  />
                </svg>
              </button>
            }
          </div>
          <div className="position-relative">
            <div className={show ? "project-description text-left show" : "project-description text-left"} id="project-description">
              <div className="container">
                <div className="row position-relative">
                  <div className="col-md-6 col-12 pe-md-4">
                    <h2 className="mb-4 fw-normal">Project Overview</h2>
                    <p className="mb-md-4 fw-normal">{data.project_overview}</p>
                    <h2 className="mb-4 fw-normal mt-md-0 mt-5 pt-md-2">Problem Statement </h2>
                    <p className="mb-0 fw-normal">{data.problem_statement}</p>
                  </div>
                  <div className="col-md-6 col-12 ps-md-4 position-relative">
                    <h2 className="mb-4 fw-normal mt-md-0 mt-5">Our Approach </h2>
                    <p className="mb-4 fw-normal">{data.our_approach}</p>
                    <div className="d-flex flex-wrap cats-12 w-100 align-items-md-end">
                      <div className="col-12 col-md-6">
                        <ul className="list-unstyled list-group category">
                          {data && showMoreCategory(data)}
                        </ul>
                      </div>
                      <div className="col-12 col-md-6 text-end">
                        <button
                          className="read-less-btn bg-white text-center px-5 py-3 mt-md-0 mt-4 border w-auto rounded-pill h-auto"
                          onClick={showMore}
                          id="less_btn"
                        >
                          <svg
                            width={13}
                            height={13}
                            viewBox="0 0 13 13"
                            fill="none"
                            className="me-2 ms-0"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.6 12.25L0.375 11.025L5.275 6.125L0.375 1.225L1.6 0L6.5 4.9L11.4 0L12.625 1.225L7.725 6.125L12.625 11.025L11.4 12.25L6.5 7.35L1.6 12.25Z"
                              fill="black"
                            />
                          </svg>
                          CLOSE{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  const projectImages = (item) => {
    let data = item && item.project_images && item.project_images.split(',');
    return (
      <>
        {data && data.map((img, index) => (
          <img key={img} src={configData.API_BASE_URL + '/' + img} className={index === 0 ? "img-fluid px-32" : "img-fluid mt-3 mt-md-5 px-32"} />
        ))}
      </>
    );
  }

  const projectVideo = (item) => {
    console.log('videos', videos)
    // let data = item && item.project_video && item.project_video.split(',');
    return (
      <>
        {videos && videos.map((img, index) => (
          <>
            {/* <video width="100%" controls className="img-fluid mt-3 mt-md-5 px-32" >
              <source type="video/mp4" src={configData.API_BASE_URL + '/' + img} />
            </video> */}
            <div className="px-32" key={img}>
              <Player className="img-fluid mt-3 mt-md-5 px-32 w-100">
                <source src={configData.API_BASE_URL + '/' + img} />
              </Player>
            </div>
          </>
        ))}
      </>
    );
  }

  const projectLoopVideo = (item) => {
    let data = item && item.project_loop_video && item.project_loop_video.split(',');
    return (
      <>
        {data && data.map((img, index) => (
          <>
            <video width="100%" key={img} autoPlay muted loop  className="img-fluid mt-3 mt-md-5 px-32" >
              <source type="video/mp4" src={configData.API_BASE_URL + '/' + img} />
            </video>
            {/* <div className="px-32">
              <Player className="img-fluid mt-3 mt-md-5 px-32 w-100">
                <source src={configData.API_BASE_URL + '/' + img} />
              </Player>
            </div> */}
          </>
        ))}
      </>
    );
  }


  return (
    <>
      <div className="project-details">
        <Navbar_1 />
        <main className="main-sec">
          <section className="project-page">
            <div className="container px-0">
              <div className="project-intro pb-mob-0 pt-40">
                <div className="row">

                  <div className="col-12">
                    <Link to="/project" className="back-to-project">
                      <p className="ibm-sans mb-md-2 mb-0 mt-n50 text-md-end text-start fs-6 pt-md-3 pt-sm-3 underline-off-2">
                        <svg
                          width={16}
                          height={15}
                          viewBox="0 0 16 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="me-2"
                        >
                          <path d="M3 15V13H10.1C11.15 13 12.0625 12.6667 12.8375 12C13.6125 11.3333 14 10.5 14 9.5C14 8.5 13.6125 7.66667 12.8375 7C12.0625 6.33333 11.15 6 10.1 6H3.8L6.4 8.6L5 10L0 5L5 0L6.4 1.4L3.8 4H10.1C11.7167 4 13.1042 4.525 14.2625 5.575C15.4208 6.625 16 7.93333 16 9.5C16 11.0667 15.4208 12.375 14.2625 13.425C13.1042 14.475 11.7167 15 10.1 15H3Z" />
                        </svg>
                        BACK TO PROJECTS
                      </p>
                    </Link>
                    <h1 className="display-5 ibm-serif color-black proDetailHeading mt-md-2 mt-0 fw-bold">
                    {/* <h1 className="display-5 ibm-serif color-black mb-md-1 mb-2 mt-md-2 mt-0 fw-bold"> */}
                      {data && data.productName}
                    </h1>
                    <h5 className="ibm-sans fw-normal underline-off-2 project-breadchrumb previous-element next-element proDetailHeading2">
                    {/* <h5 className="ibm-sans fw-normal mb-md-5 underline-off-2 project-breadchrumb previous-element next-element mt-2"> */}
                      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data && showAllCategoryWithoutC(data)) }} />
                    </h5>
                  </div>
                </div>
                <h4 className="ibm-sans fw-normal display-5 color-black letter-space mb-0 underline-off-2 proDetailHeading3">
                {/* <h4 className="ibm-sans fw-normal display-5 color-black letter-space mb-0 underline-off-2 "> */}
                  {data && data.project_heading}
                </h4>
                <div className="project-intro pt-16">
                  <p className="letter-spacing">
                    {data && data.project_intro}
                  </p>
                </div>

              </div>
              <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data && data.description) }} />

            </div>
            <div className="img-section">
              <div className="container prl-32">
                {data && projectImages(data)}
                {videos && projectVideo()}
                {data && projectLoopVideo(data)}
              </div>
            </div>
            {data && addmoreProjectData(data)}
            <div className="more-projects bg-white px-32 position-relative pb-5 d-md-flex gap-40 ">
              {relativeData()}
              {/* <div className="single-proj">
                <img src="../img/view-more-proj.webp" className="d-block w-100" alt="..." />
                <div className="container">
                  <h2 className="fw-bold pt-3 fs-22 mb-0">TREK (P1)</h2>
                  <p className="lh-scope">Brand Identity, Packaging Design, Web Design</p>
                  <div className="left-icon flip-1 position-absolute">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={38}
                      height={38}
                      viewBox="0 0 38 38"
                      fill="none"
                    >
                      <path
                        d="M19.2273 12.4654L18.218 13.4748L22.9992 18.2561L12.1086 18.2561L12.1086 19.7436L22.9992 19.7436L18.218 24.5248L19.2273 25.5342L25.7617 18.9998L19.2273 12.4654ZM19.2181 0.512305C21.7743 0.512305 24.1779 0.997351 26.4289 1.96745C28.6799 2.93758 30.638 4.25417 32.3032 5.91719C33.9683 7.58025 35.2865 9.53587 36.2578 11.784C37.2292 14.0322 37.7148 16.4344 37.7148 18.9906C37.7148 21.5467 37.2298 23.9503 36.2597 26.2013C35.2896 28.4524 33.973 30.4105 32.31 32.0756C30.6469 33.7407 28.6913 35.059 26.4431 36.0303C24.1949 37.0016 21.7928 37.4873 19.2366 37.4873C16.6804 37.4873 14.2768 37.0023 12.0258 36.0322C9.77478 35.062 7.81669 33.7454 6.15158 32.0824C4.48643 30.4194 3.16819 28.4637 2.19685 26.2156C1.22551 23.9674 0.739847 21.5652 0.739847 19.009C0.739847 16.4529 1.22489 14.0493 2.19499 11.7983C3.16513 9.54723 4.48171 7.58914 6.14473 5.92399C7.80779 4.25887 9.76341 2.94064 12.0116 1.96931C14.2598 0.997972 16.6619 0.512304 19.2181 0.512305Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="single-proj pt-3 pt-md-0">
                <img src="../img/Mask group.png" className="d-block w-100" alt="..." />
                <div className="container">
                  <h2 className="fw-bold pt-3 fs-22 mb-0">Panchamrit(p2)</h2>
                  <p className="lh-scope">Brand Identity, Packaging Design</p>
                  <div className="right-icon position-absolute">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={38}
                      height={38}
                      viewBox="0 0 38 38"
                      fill="none"
                    >
                      <path
                        d="M19.2273 12.4654L18.218 13.4748L22.9992 18.2561L12.1086 18.2561L12.1086 19.7436L22.9992 19.7436L18.218 24.5248L19.2273 25.5342L25.7617 18.9998L19.2273 12.4654ZM19.2181 0.512305C21.7743 0.512305 24.1779 0.997351 26.4289 1.96745C28.6799 2.93758 30.638 4.25417 32.3032 5.91719C33.9683 7.58025 35.2865 9.53587 36.2578 11.784C37.2292 14.0322 37.7148 16.4344 37.7148 18.9906C37.7148 21.5467 37.2298 23.9503 36.2597 26.2013C35.2896 28.4524 33.973 30.4105 32.31 32.0756C30.6469 33.7407 28.6913 35.059 26.4431 36.0303C24.1949 37.0016 21.7928 37.4873 19.2366 37.4873C16.6804 37.4873 14.2768 37.0023 12.0258 36.0322C9.77478 35.062 7.81669 33.7454 6.15158 32.0824C4.48643 30.4194 3.16819 28.4637 2.19685 26.2156C1.22551 23.9674 0.739847 21.5652 0.739847 19.009C0.739847 16.4529 1.22489 14.0493 2.19499 11.7983C3.16513 9.54723 4.48171 7.58914 6.14473 5.92399C7.80779 4.25887 9.76341 2.94064 12.0116 1.96931C14.2598 0.997972 16.6619 0.512304 19.2181 0.512305Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </div>
              </div>
                      */}
            </div>
          </section>
        </main>
        <Footer_1 />
      </div>
    </>
  );
}

export default ProjectDetails;
